import React, { Fragment } from "react"
import Helmet from "react-helmet"
import { graphql, Link } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import PostListing from "../components/PostListing"
import config from "../../data/SiteConfig"

const ListingTitle = styled("h2")`
  font-size: 20px;
  text-transform: uppercase;
  font-family: 'Dattilo DJR Banner Web';
  font-weight: 900;
  margin-bottom: 30px;
`

const categorize = (postEdges) => {
  const categoryStrings = [];
  const postsByCategories = {};
  postEdges.forEach(postEdge => {
    const { category } = postEdge.node.frontmatter;
    if (postsByCategories[category]){
      postsByCategories[category].push(postEdge);
    } else {
      postsByCategories[category] = [postEdge];
    }
  })
  return postsByCategories;
}

// Index is the List of all posts, arranged by category
export default ({ data }) => {
  const postEdges = data.allMarkdownRemark.edges
  const categorized = categorize(postEdges);
  const categoryStrings = Object.keys(categorized);

  const displayCategorized = categoryStrings.map(name => 
    <Fragment>
      <ListingTitle>{name}</ListingTitle>
      <PostListing postEdges={categorized[name]} />
    </Fragment>
  )

  return (
    <Layout>
      <div className="listing-container">
        <div className="posts-container">
          <Helmet title={config.siteTitle} />
          {displayCategorized}
        </div>
      </div>
    </Layout>
  )
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query PageQuery {
    allMarkdownRemark(sort: { fields: [fields___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            date
            category
          }
        }
      }
    }
  }
`
